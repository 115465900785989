<template>
  <div>
    <div v-if="provider.title">
      <div class="mb-2 d-flex align-center">
        <img
          :src="provider.icon"
          :alt="provider.title"
          class="social-icon mr-1"
        />
        <strong>{{ provider.title }}</strong>
      </div>
      <v-chip v-if="isLegacy" small color="error" class="mb-2">
        <v-icon left size="18px" title="Удалить тег">mdi-alert</v-icon>
        Устаревший аккаунт</v-chip
      >
      <option-row v-if="social.uid"
        ><span slot="title">UID аккаунта:</span>#{{ social.uid }}</option-row
      >
      <option-row v-if="social.email"
        ><span slot="title">Email аккаунта:</span>{{ social.email }}</option-row
      >
      <option-row v-if="social.first_name"
        ><span slot="title">Имя:</span>{{ social.first_name }}</option-row
      >
      <option-row v-if="social.last_name"
        ><span slot="title">Фамилия:</span>{{ social.last_name }}</option-row
      >
      <option-row v-if="social.username"
        ><span slot="title">Имя пользователя:</span
        >{{ social.username }}</option-row
      >
      <option-row v-if="createDate"
        ><span slot="title">Дата регистрации:</span>{{ createDate }}</option-row
      >
      <option-row v-if="modifyDate"
        ><span slot="title">Дата обновления:</span>{{ modifyDate }}</option-row
      >
      <option-row v-if="authTime"
        ><span slot="title">Дата авторизации:</span>{{ authTime }}</option-row
      >
      <option-row
        v-if="lastModifyDate && showSync"
        title="Дата синхронизации:"
        >{{ lastModifyDate }}</option-row
      >
      <div v-if="showSync" class="d-flex align-center">
        <a
          href="#"
          class="link mr-1"
          :class="{ 'grey--text': pending }"
          :style="{ cursor: pending ? 'not-allowed' : 'pointer' }"
          @click.stop.prevent="syncSocial"
          >Синхронизировать провайдера
        </a>
        <v-tooltip bottom color="primary" max-width="250px">
          <template #activator="{ on, attrs }">
            <v-icon size="20px" color="primary" v-bind="attrs" v-on="on"
              >mdi-help-circle-outline</v-icon
            >
          </template>
          <span
            >При синхронизации данные будут обновлены для всех привязанных
            аккаунтов данного провайдера. Обновление данных занимает
            не&nbsp;более суток.</span
          >
        </v-tooltip>
      </div>
      <a
        v-if="link"
        :href="link"
        target="_blank"
        rel="noopener noreferrer"
        class="link"
        >Перейти к аккаунту</a
      >
    </div>
  </div>
</template>

<script>
import {
  SOCIAL_PROVIDERS,
  SOCIAL_PROVIDERS_SLUGS,
  PROVIDERS_FOR_SYNC,
} from "@/constants";
import { talentClient } from "@/api";

import dayjs from "dayjs";
export default {
  name: "SocialCard",
  props: {
    social: {},
    lastModify: [String, undefined],
  },
  data() {
    return {
      pending: false,
    };
  },
  computed: {
    provider() {
      return SOCIAL_PROVIDERS[this.social.provider];
    },
    talentUser() {
      return this.$store.state.talent.users[this.$route.params.id];
    },
    link() {
      const { social } = this;
      switch (social.provider) {
        case SOCIAL_PROVIDERS_SLUGS.telegram:
          return social.username
            ? `https://telegram.im/@${social.username}`
            : "";
        case SOCIAL_PROVIDERS_SLUGS.kaggle:
          return social.username
            ? `https://www.kaggle.com/${social.username}`
            : "";
        default:
          return social.profile_url;
      }
    },
    createDate() {
      if (!this.social.created_at) return;
      return dayjs(this.social.created_at).format("LLL");
    },
    modifyDate() {
      const modifyDate = this.social.modified || this.social.updated_at;
      if (!modifyDate) return;
      return dayjs(modifyDate).format("LLL");
    },
    showSync() {
      return PROVIDERS_FOR_SYNC[this.social.provider];
    },
    lastModifyDate() {
      if (!this.lastModify) return;
      return dayjs(this.lastModify).format("LLL");
    },
    authTime() {
      if (!this.social.auth_time) return;
      return dayjs(this.social.auth_time * 1000).format("LLL");
    },
    isLegacy() {
      return this.social.provider.includes("legacy");
    },
  },
  methods: {
    async syncSocial() {
      if (this.pending) return;
      this.pending = true;
      try {
        await talentClient({
          method: "POST",
          url: `users/${this.talentUser.id}/social-auths/sync/${this.social.provider}/`,
        });
        this.$toast(
          `Задача на обновление добавлена. ${this.social.provider} в скором времени будет синхронизирован`,
          { type: "success" }
        );
      } catch (error) {
        console.error(error);
        const message =
          "Ошибка синхронизации." + ` ${error?.message ? error.message : ""}`;
        this.$toast(message, { type: "error" });
      }
      this.pending = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.social-icon {
  width: 24px;
  height: 24px;
}
</style>
